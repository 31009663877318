var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_LOCATIONS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Locations' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-map-big"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))])])]}}],null,false,512351142)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ORGANIZATIONS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Organizations' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-building"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATIONS")))])])]}}],null,false,4191298443)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Resellers' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-shop"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.RESELLERS")))])])]}}],null,false,3160578900)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_USERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Users' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-single-02"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.USERS")))])])]}}],null,false,198087842)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ROLES))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Roles' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-hat-3"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.ROLES")))])])]}}],null,false,705850353)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }